<template>
  <div class="study-room-content-container">
    <section id="container">
      <section id="content">
        <section class="studybox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Study Room</h2>
          </div>
          <div class="studybox_h3_box">
            <div class="studybox_h3_box_in">
              <img src="/image/common/top_title_wood_bg02.png" style="float:left;"/>
              <div class="study-room-book-header">
                <span class="book-code">{{ schedule.content_name }}</span>
                <h3 class="book-title">{{ schedule.content_title }}</h3>
              </div>
              <img src="/image/common/top_title_wood_bg04.png" style="float:left;"/>
            </div>
          </div>
          <div class="studybox_h4_box">
            <h4>Listening & Listening</h4>
          </div>
          <section class="study_myinfo_list_area study-room-main-content-container" style="height:80%;">
            <div class="mypage_scroll_content">
              <section class="study_myinfo_list_box">
                <!-- content -->
                <div class="study_myinfo_list_box_01">
                  <div class="study_myinfo_list_box_title01">예습</div>
                  <ul>
                    <template
                      v-for="(content, key) in contents"
                    >
                      <li
                        v-if="content.container === 'pre-lesson'"
                        v-bind:key="key"
                        v-bind:class="[content.type === 'quiz' || content.type === 'comprehension-test' || content.type === 'vocabulary-check' || content.type === 'listening-test' || content.type === 'word-test' || content.type === 'writing' ? 'study_myinfo_play_btn02' : '', '']"
                      >
                        <a
                           href="#"
                          @click.prevent="$router.push({ name: `demo.study-room.${content.type}.content`, params: { talkScheduleId: talkScheduleId, studyRoomId: content.id } })"
                        >
                          <span v-if="content.type === 'translation'"><img src="/image/sub/study_info_ic_02.png" /><h4>직독직해</h4></span>
                          <span v-if="content.type === 'listen-rec'"><img src="/image/sub/study_info_ic_03.png" /><h4>문장녹음</h4></span>
                          <span v-if="content.type === 'video'"><img src="/image/sub/study_info_ic_01.png" /><h4>영상보기</h4></span>
                          <span v-if="content.type === 'audio'"><img src="/image/sub/study_info_ic_01.png" /><h4>Audio</h4></span>
                          <span v-if="content.type === 'listening-test'"><img src="/image/sub/study_info_ic_06.png" /><h4>Listening Test</h4></span>
                          <span v-if="content.type === 'comparehension-test'"><img src="/image/sub/study_info_ic_06.png" /><h4>Comprehension Test</h4></span>
                          <span v-if="content.type === 'vocabulary-check'"><img src="/image/sub/study_info_ic_06.png" /><h4>Vocabulary Check</h4></span>
                          <span v-if="content.type === 'comprehension-test'"><img src="/image/sub/study_info_ic_06.png" /><h4>Comprehension Test</h4></span>
                          <span v-if="content.type === 'word-test'"><img src="/image/sub/study_info_ic_06.png" /><h4>Word Test</h4></span>
                          <span v-if="content.type === 'quiz'"><img src="/image/sub/study_info_ic_06.png" /><h4>Quiz</h4></span>
                          <span v-if="content.type === 'writing'"><img src="/image/sub/study_info_ic_06.png" /><h4>Writing</h4></span>
                        </a>
                      </li>
                    </template>
                  </ul>
                  <div class="study_myinfo_list_box_bottom"></div>
                </div>
                <div class="study_myinfo_list_box_01" >
                  <div class="study_myinfo_list_box_title02">화상수업</div>
                  <ul style="margin-top:-20px;">
                    <li class="study_myinfo_play_btn01">
                      <a href="#" @click="enterRoom(schedule)"><img src="/image/sub/study_info_ic_04.png" /><h4>화상입장</h4></a>
                    </li>
                    <li class="study_myinfo_play_btn01" style="background: none;">
                      <a href="#"><img src="/image/sub/study_info_ic_05.png" /><h4>화상테스트</h4></a>
                    </li>
                  </ul>
                  <div class="study_myinfo_list_box_bottom"></div>
                </div>
                <div class="study_myinfo_list_box_01" >
                  <div class="study_myinfo_list_box_title03">복습</div>
                  <ul>
                    <template
                      v-for="(content, key) in contents"
                    >
                      <li
                        v-if="content.container === 'post-lesson'"
                        v-bind:key="key"
                        v-bind:class="[content.type === 'quiz' || content.type === 'comprehension-test' || content.type === 'vocabulary-check' || content.type === 'listening-test' || content.type === 'word-test' || content.type === 'writing' ? 'study_myinfo_play_btn02' : '', '']"
                      >
                        <a
                           href="#"
                          @click.prevent="$router.push({ name: `demo.study-room.${content.type}.content`, params: { talkScheduleId: talkScheduleId, studyRoomId: content.id } })"
                        >
                          <span v-if="content.type === 'translation'"><img src="/image/sub/study_info_ic_02.png" /><h4>직독직해</h4></span>
                          <span v-if="content.type === 'listen-rec'"><img src="/image/sub/study_info_ic_03.png" /><h4>문장녹음</h4></span>
                          <span v-if="content.type === 'video'"><img src="/image/sub/study_info_ic_01.png" /><h4>영상보기</h4></span>
                          <span v-if="content.type === 'audio'"><img src="/image/sub/study_info_ic_01.png" /><h4>Audio</h4></span>
                          <span v-if="content.type === 'listening-test'"><img src="/image/sub/study_info_ic_06.png" /><h4>Listening Test</h4></span>
                          <span v-if="content.type === 'comparehension-test'"><img src="/image/sub/study_info_ic_06.png" /><h4>Comprehension Test</h4></span>
                          <span v-if="content.type === 'vocabulary-check'"><img src="/image/sub/study_info_ic_06.png" /><h4>Vocabulary Check</h4></span>
                          <span v-if="content.type === 'comprehension-test'"><img src="/image/sub/study_info_ic_06.png" /><h4>Comprehension Test</h4></span>
                          <span v-if="content.type === 'word-test'"><img src="/image/sub/study_info_ic_06.png" /><h4>Word Test</h4></span>
                          <span v-if="content.type === 'quiz'"><img src="/image/sub/study_info_ic_06.png" /><h4>Quiz</h4></span>
                          <span v-if="content.type === 'writing'"><img src="/image/sub/study_info_ic_06.png" /><h4>Writing</h4></span>
                        </a>
                      </li>
                    </template>
                    <li class="study_myinfo_play_btn02" style="background: none;">
                      <a href="#"><img src="/image/sub/study_info_ic_07.png" /><h4>화상수업<br />영상보기</h4></a>
                    </li>
                  </ul>
                  <div class="study_myinfo_list_box_bottom"></div>
                </div>
                <!-- //content -->
              </section>
              <button v-if="cancelable" style="padding: 5px;" @click="postpone()">
                Postpone Schedule On {{ schedule.schedule }}
              </button>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'demo.study-room' }"></footer-navigation>
      </section>
    </section>
  </div>
</template>

<script>

import FooterNavigation from '@/layouts/components/FooterNavigation'
import StudyRoom from '@/models/StudyRoom'
import Engducate from '@/models/Engducate'

export default {
  name: 'Demo.StudyRoom.Content',
  components: {
    FooterNavigation
  },
  data () {
    return {
      loading: true,
      cancelable: false,
      contents: [],
      schedule: {},
      talkScheduleId: typeof this.$route.params.talkScheduleId !== 'undefined' ? this.$route.params.talkScheduleId : '',
      demo: true
    }
  },
  mounted () {
    this.loadTalkSchedule(this.talkScheduleId)
  },
  methods: {
    async loadTalkSchedule (id) {
      this.loading = true
      try {
        const response = await StudyRoom.demoView({ id: id, demo: this.demo })
        this.contents = response.data.contents
        this.schedule = response.data.schedule
        this.cancelable = response.data.cancelable
      } catch (e) {
        this.$swal({
          title: 'Error',
          text: e.response.data,
          icon: 'error'
        })
      }
      this.loading = false
    },
    async enterRoom (schedule) {
      try {
        const response = await Engducate.create({
          talkId: schedule.id,
          redirectUrl: window.location.href
        })
        window.open(response.data.url)
      } catch (e) {
        alert(e.response.data ?? e.message)
      }
    },
    async postpone () {
      const doWePostpone = await this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, postpone it!',
        className: 'swal2-styled',
        customClass: {
          confirmButton: 'swal2-confirm btn btn-primary swal2-styled',
          cancelButton: 'swal2-cancel swal2-styled'
        },
        buttonsStyling: false
      })

      if (doWePostpone.value) {
        try {
          const response = await StudyRoom.demoPostpone(this.talkScheduleId)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
          this.$router.replace({ name: 'demo.study-room' })
        } catch (e) {
          this.$swal({
            title: 'Error',
            text: e.response.data.message,
            icon: 'error'
          })
        }
      }
    }
  }
}
</script>

<style>

</style>
